"use client"

import Link from "next/link"
import Bugsnag from "@bugsnag/js"
import { useContext } from "react"
import { UserContext } from "@/context/UserContext"
import { Copyright } from "@/components/site/Copyright"
import { useFirebase } from "@/firebase/FirebaseProvider"

export default function Footer() {
  const { auth } = useFirebase()
  const userContext = useContext(UserContext)

  const signOut = () => {
    auth.signOut().catch((err) => {
      Bugsnag.notify(new Error("Error when signing out", { cause: err }))
    })
  }

  const isSignedIn = userContext?.userV2

  return (
    <footer className={"mt-[40px] flex w-full items-start justify-center"}>
      <div
        className={
          "w-full max-w-[1080px] border-t border-black/[.1] py-[15px] sm:py-[25px]"
        }
      >
        <nav className={"flex flex-row justify-between"}>
          <Copyright />
          <div className={"flex flex-row justify-between"}>
            <Link
              className={
                "pl-5 text-[15px] font-normal text-black/[.35] hover:text-[#4350de]"
              }
              href={{ pathname: "/terms" }}
            >
              Terms
            </Link>
            <Link
              className={
                "pl-5 text-[15px] font-normal text-black/[.35] hover:text-[#4350de]"
              }
              href={{ pathname: "/privacy" }}
            >
              Privacy
            </Link>
            {isSignedIn && (
              <button
                className={
                  "pl-5 text-[15px] font-normal text-black/[.35] hover:text-[#4350de]"
                }
                onClick={() => {
                  signOut()
                }}
              >
                Log out
              </button>
            )}
          </div>
        </nav>
      </div>
    </footer>
  )
}
