'use client'

import Footer from "@/components/site/Footer";
import {GetTheApp2} from "@/components/GetTheApp";

export default function Home() {
    return (
        <div className={"px-[24px] flex flex-col min-h-[100vh] justify-between items-center max-w-[468px] mx-auto"}>
            <div className={"flex flex-col items-center justify-center w-full mt-[15vh]"}>
                <div className={"flex flex-row items-center relative"}>
                    <video id="loop_me"
                           poster="/logo.jpg"
                           width="150"
                           height="150"
                           className={"absolute left-[-40px]"}
                           muted
                           playsInline={true} autoPlay={true} loop src="/logo.mov">
                        <source src="/logo.mov" type="video/mp4"/>
                    </video>
                    <img
                        className={'z-10 newie-heading-home'}
                        width={490}
                        height={112}
                        alt={'newie-logo'}
                        src={'/newie.webp'}
                    />
                </div>
                <p className={"home-subtitle"}>
                    The easiest way to buy or sell any service.
                </p>
                <GetTheApp2/>
            </div>
            <Footer/>
        </div>
    )
}
