"use client"

import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import Bugsnag from "@bugsnag/js"
import { useEffectOnce } from "usehooks-ts"
import { useFirebase } from "@/firebase/FirebaseProvider"
import { getDoc } from "@firebase/firestore"
import { doc } from "firebase/firestore"
import {
  onAuthStateChanged,
  signInWithCredential,
  signInWithCustomToken,
} from "@firebase/auth"
import { OAuthProvider, UserInfo } from "firebase/auth"

export type UserContextValue = {
  user?: UserInfo
  setUser?: Dispatch<SetStateAction<any>>
  userV2?: { uid: string; email?: string; displayName?: string }
  loadingUser: boolean
  setNewUserName?: Dispatch<SetStateAction<any>>
  isUserNameMissing?: boolean
  userRole?: string
  isUserRoleLoading?: boolean
}

export const UserContext = createContext<UserContextValue>({
  loadingUser: true,
})

export default function UserContextComp({ children }: PropsWithChildren) {
  const { auth, firestore } = useFirebase()
  const [user, setUser] = useState<undefined | UserInfo>()
  const [userV2, setUserV2] = useState<undefined | UserContextValue["userV2"]>()
  const [loadingUser, setLoadingUser] = useState<boolean>(true)
  const [userRole, setUserRole] = useState<undefined | string>()

  const setUserWrapper = useCallback((user: UserInfo | undefined) => {
    setUser(user)
    setUserV2(
      user
        ? {
            uid: user.uid,
            ...(user.email && { email: user.email }),
            ...(user.displayName && { displayName: user.displayName }),
          }
        : undefined,
    )
  }, [])

  useEffect(() => {
    if (userV2) {
      getDoc(doc(firestore, `users/${userV2.uid}/auth/self`))
        .then((x) => {
          setUserRole(x.data()?.role || "")
        })
        .catch((err) => {
          if (err.message !== "Missing or insufficient permissions.") {
            Bugsnag.notify(
              new Error("Error when fetching the user's role", { cause: err }),
            )
          }
        })
    }
  }, [firestore, userV2])

  // Set bugsnag user
  useEffect(() => {
    if (userV2) {
      Bugsnag.setUser(
        userV2.uid,
        userV2.email || undefined,
        userV2.displayName || undefined,
      )
    }
  }, [userV2])

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      // console.log("[UserContextComp:useEffect]", { user })
      setUserWrapper(user || undefined)
      setLoadingUser(false)
    })
  }, [auth, setUserWrapper])

  useEffectOnce(() => {
    const newieDebugging = {}
    // @ts-ignore
    window.newieDebugging = newieDebugging

    onAuthStateChanged(auth, (user) => {
      // @ts-ignore
      newieDebugging.currentUser = user
    })

    // @ts-ignore
    newieDebugging.signInWithProviderCredential = async (
      providerId: string,
      credentialJSON: string,
    ) => {
      await signInWithCredential(
        auth,
        new OAuthProvider(providerId).credential({
          idToken: credentialJSON,
        }),
      )
    }
    // @ts-ignore
    newieDebugging.signInWithCustomToken = async (token: string) => {
      await signInWithCustomToken(auth, token)
    }
    // @ts-ignore
    newieDebugging.signOut = async () => {
      await auth.signOut()
    }
  })

  return (
    <UserContext.Provider
      value={{
        user,
        userV2,
        setUser: setUserWrapper,
        loadingUser,
        userRole,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = (): UserContextValue => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error("useUser must be used within a UserContextProvider")
  }
  return context
}
